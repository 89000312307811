import request from "@/config/serve.js";
import ObsClient from "esdk-obs-browserjs/src/obs";
const regPhone = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
const regPhoneTel =
  /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/;
// 将base64的图片转换成url
export function base64ImgtoUrl(dataurl, filename = "file") {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split("/")[1];
  let bstr = window.atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const imgFile = new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
  const url = window.URL.createObjectURL(imgFile);
  return url;
}
export function checkPhone(rule, value, cb) {
  if (regPhone.test(value)) {
    //合法的
    return cb();
  }
  cb(new Error("请输入正确的手机号"));
}
export function checkEmail(rule, value, cb) {
  if (regEmail.test(value)) {
    //合法的
    return cb();
  }
  cb(new Error("请输入正确的邮箱"));
}
export function checkPhoneTel(rule, value, cb) {
  if (regPhoneTel.test(value)) {
    //合法的电话
    return cb();
  }
  cb(new Error("请输入正确的电话"));
}
//文件地址判断是不是pdf
export function pdfType(fileName) {
  let suffix = "";
  // 获取类型结果
  let result = "";
  try {
    const flieArr = fileName.split(".");
    suffix = flieArr[flieArr.length - 1];
  } catch (err) {
    suffix = "";
  }
  const pdflist = ["pdf"];
  result = pdflist.find((item) => item === suffix);
  if (result) {
    return true;
  } else {
    return false;
  }
}
// 下载文件，解决跨域会直接打开，不下载问题
export function downloadFile(url, fileName) {
  let _fileName;
  // 传入下载文件名则用传入的文件名，否则使用url中的后缀
  if (fileName) {
    _fileName = fileName;
  } else {
    _fileName = url.substring(url.lastIndexOf("/") + 1);
  }
  const x = new XMLHttpRequest();
  x.open("GET", url, true);
  x.responseType = "blob";
  // x.onload = function (e) {
  x.onload = function () {
    // console.log(e);
    const url = window.URL.createObjectURL(x.response);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = _fileName;
    a.click();
    a.remove();
  };
  x.send();
}
export function obsServe(file, uploadType = "other") {
  return new Promise((resolve, rej) => {
    request("get", "/safe/getAccess", {}, false).then((res) => {
      let hwSecret = res.data.credential;
      let obsClient = new ObsClient({
        timeout: 3600,
        access_key_id: hwSecret.access,
        secret_access_key: hwSecret.secret,
        security_token: hwSecret.securitytoken,
        server: "https://obs.cn-north-4.myhuaweicloud.com",
      });
      const fileName = file.name;
      const fileType = fileName.split(".").pop();
      // 随机数和时间戳生成唯一的key
      const fileId =
        (Math.random() + "").substring(2, 12) + "_" + new Date().getTime();
      const _fileName = fileId + "." + fileType;
      // 传入不同的文件地址
      let key = "market_develop/other/";
      if (["image", "video"].includes(uploadType)) {
        key = "market_develop/images/";
      } else if (uploadType === "apk") {
        key = "market_develop/apk/";
      }
      let putParams = {
        Bucket: "newmarket3-droish", // 桶名
        Key: key + _fileName, // 路径 + 文件名
        SourceFile: file, // 上传的文件
      };
      obsClient.putObject(putParams, function (err, result) {
        const obj = {
          accessPath: "https://newmarket4.tt286.com/" + key + _fileName, //返回访问链接
          fileId,
          fileName: fileName,
          fileSize: file.size,
          fileType: file.type,
          suffix: fileType,
        };
        if (err) {
          console.error("Error-->" + err);
        } else {
          resolve(obj);
        }
      });
    });
  });
}
// 判断date2时间是否在date1时间之后 compareDates("2021-09-15", "2022-01-01")  -1表示date1小于date2
export function compareDates(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  if (d1 < d2) {
    return -1; // date1 小于 date2
  } else if (d1 > d2) {
    return 1; // date1 大于 date2
  } else {
    return 0; // date1 等于 date2
  }
}
// 数字转为千分位，并保留两个小数位
export function numberFilter(oldNum, isFixed) {
  if (!oldNum && oldNum != 0) return "-"; // 传入的数值为空直接返回空对象
  let newNum = oldNum.toLocaleString("en-US"); // 数字转成千分位 = 123,456.78
  const numArr = newNum.split("."); // 按小数点吧数字拆分 = [123,456, 78]
  if (!isFixed) {
    if (!numArr[1]) {
      newNum = newNum + ".00";
    } else if (numArr[1].length === 1) {
      newNum = newNum + "0";
    } else if (numArr[1].length >= 2) {
      newNum = numArr[0] + "." + numArr[1].substr(0, 2);
    }
  }
  return newNum;
}
